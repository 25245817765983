export function _(x) {
    return document.getElementById(x);
}
export function createEl(tag, classes) {
    const el = document.createElement(tag);
    el.setAttribute('class', classes);
    return el;
}

//FADE IN/FADE OUT
export function fadeOut(e, opLimit) {
    opLimit = opLimit || 1;
    e.style.opacity = opLimit;
    (function fade(x) {
        (e.style.opacity -= 0.1) < 0 ? e.classList.add('no-display') : setTimeout(fade, 40);
    })();
}
export function fadeIn(e, flex, opLimit) {
    if (!flex) { e.classList.remove('no-display'); }
    else { e.classList.remove('no-display'); e.classList.add('flex-container'); }
    let opacity = 0;
    opLimit = opLimit || 1;
    var interval = setInterval(() => {
        if (opacity < opLimit) {
            opacity += 0.1;
            e.style.opacity = opacity;
        }
        else { clearInterval(interval); }
    }, 40);
}

//OVERLAYS
function overlaySwitch(elClass) {
    let el, closeEl;
    switch (elClass) {
        case 'image-overlay-change':
            el = _('imgOverlay');
            closeEl = _('imgOverlay');
    }
    return { el, closeEl };
}
export function onCloseOverlay(e) {
    const elClass = this.getAttribute('data-class');
    const el = overlaySwitch(elClass).el;
    const closeEl = overlaySwitch(elClass).closeEl;
    hideOverlay(el, closeEl, elClass, 0.8);
}
export function showOverlay(el, closeEl, elClass) {
    el.setAttribute('data-class', elClass);
    closeEl.setAttribute('data-class', elClass);
    overlay.setAttribute('data-class', elClass);
    fadeIn(overlay, false);
    document.documentElement.classList.add('no-scroll');
    el.style.visibility = 'visible';
    el.classList.add(elClass);
    closeEl.style.visibility = 'visible';
}
function hideOverlay(el, closeEl, elClass, opLimit) {
    opLimit = opLimit || 1;
    document.documentElement.classList.remove('no-scroll');
    el.classList.remove(elClass);
    el.style.visibility = 'hidden';
    closeEl.style.visibility = 'hidden';
    setTimeout(() => { fadeOut(overlay, opLimit); }, 400);
}

//SPINNER
export function spinnerHandler(loading, spinner, button) {
    if (loading) {
        spinner.classList.remove("no-display");
        button.classList.add("no-display");
    }
    else {
        spinner.classList.add("no-display");
        button.classList.remove("no-display");
    }
}
export var requestLoading = function (isLoading, btn) {
    const spinner = btn.querySelector('.spinner');
    const button = btn.querySelector('.btn-span');
    if (isLoading) {
        btn.disabled = true;
        btn.classList.add('btn-disabled');
    } else {
        btn.disabled = false;
        btn.classList.remove('btn-disabled');
    }
    spinnerHandler(isLoading, spinner, button);
};