import { formatDate } from './date-formats.js';
import { createEl, fadeIn, fadeOut, onCloseOverlay, requestLoading, showOverlay, _ } from './global.js';

var body = _('body'),
    bckgd = _('bckgd'),
    overlay = _('overlay'),
    imgOverlay = _('imgOverlay'),
    nav = _('nav'),
    main = _('main'),
    pageFooter = _('pageFooter');

//ON PAGE LOAD
document.addEventListener("DOMContentLoaded", onDOMLoaded);
function onDOMLoaded() {
    const page = body.getAttribute('data-page');
    handleActiveMenu(page);
    if (page != 'will-be' && page != 'ask') { bckgd.style.opacity = 0.1; }
    main.style.opacity = 1;
    pageFooter.classList.remove('transform-footer');
    if (page === 'was') {
        _('wasHeader').classList.remove('transform-header');
        addWasListeners();
    }
    else if (page === 'will-be') { handleUpcomingShows(); }
    else if (page === 'ask') { addContactListeners(); }
    addGlobalListeners();
}
function handleActiveMenu(page) {
    const menuItems = nav.querySelectorAll('.nav-items');
    const target = Array.from(menuItems).find(node => node.getAttribute('data-page') === page);
    if (!target) { return; }
    target.classList.add('nav-active');
}

//GLOBAL LISTENERS
function addGlobalListeners() {
    const hamburger = document.querySelector('.hamburger');
    hamburger.addEventListener('click', toggleHamb, false);
    const images = document.querySelectorAll('.images');
    images.forEach(image => { image.addEventListener('click', onImgOverlay, false); });
    const overlays = [overlay, imgOverlay];
    overlays.forEach(e => { e.addEventListener('click', onCloseOverlay, false); });
    const videos = document.querySelectorAll('.video-wrapper');
    videos.forEach(vid => { vid.addEventListener('click', onVidClick, false); });
    document.documentElement.addEventListener('touchstart', function (e) {
        //hover behaviour on touch devices
    });
}

//WAS LISTENERS
function addWasListeners() {
    const wasBtns = _('wasHeader').querySelectorAll('.was-header-item');
    wasBtns.forEach(btn => { btn.addEventListener('click', onWasItemClick, false); });
}
function onWasItemClick(e) {
    const wasBtns = _('wasHeader').querySelectorAll('.was-header-item p');
    wasBtns.forEach(btn => { btn.classList.remove('was-header-item-change'); });
    this.querySelector('p').classList.add('was-header-item-change');
    adjustWasMenu(this);
    const cat = this.getAttribute('data-target');
    const blocks = main.querySelectorAll('.content-block');
    blocks.forEach(block => { block.classList.add('no-display'); });
    const targets = Array.from(blocks).filter(block => block.getAttribute('data-cat').toLowerCase() === cat.toLowerCase());
    targets.forEach(target => { target.classList.remove('no-display'); });
    document.documentElement.scrollTop = 0;
}
function adjustWasMenu(item) {
    const bounds = item.getBoundingClientRect();
    const padding = (window.innerWidth > 768) ? 60 : 12;
    _('wasHeader').scrollLeft += (bounds.x - padding);
}

//WILL-BE
function handleUpcomingShows() {
    const rows = _('showsTable').querySelectorAll('tr');
    rows.forEach(r => {
        const rowDate = r.getAttribute('data-date');
        if (new Date(rowDate) <= new Date) { return r.remove(); }
        const tdDate = r.querySelector('.td-date');
        tdDate.innerText = formatDate(new Date(rowDate));
    });
    if (_('showsTable').querySelectorAll('tr').length === 0) { appendNoShows(); }
}
function appendNoShows() {
    const tbody = _('showsTable').querySelector('tbody');
    const tr = createEl('tr', 'flex-container flex-col flex-center');
    const td = createEl('td', '');
    td.innerHTML = 'No upcoming shows at the moment<br><br>TBA soon';
    tr.append(td);
    tbody.append(tr);
}

//CONTACT
function addContactListeners() {
    _('contactForm').addEventListener('submit', onContactSubmit, false);
    const inputs = main.querySelectorAll('.input-fields');
    inputs.forEach(input => { input.addEventListener('keyup', onInput, false); });
    _('resultOverlay').addEventListener('click', onOverlayClick, false);
}
function onInput(e) {
    this.classList.add('validate');
}
async function onContactSubmit(e) {
    e.preventDefault();
    document.querySelector(":root").classList.add('no-pointers');
    requestLoading(true, _('submitBtn'));
    const payload = { fullName: this.name.value.trim(), email: this.email.value.trim(), message: this.message.value.trim() };
    const formSubmissionResult = await submitForm(payload);
    document.querySelector(":root").classList.remove('no-pointers');
    requestLoading(false, _('submitBtn'));
    onServerResult(this, formSubmissionResult);
}
async function submitForm(payload) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    try {
        const response = await fetch('https://aminobelyamani.xyz/ab-contact', options);
        return await response.json();
    }
    catch (err) { console.error(err); return { success: false, error: 'An unexpected error occured<br><br>Please check your internet connection' }; }
}
function onServerResult(form, result) {
    fadeIn(_('resultOverlay'), true);
    document.querySelector(":root").classList.add('no-scroll');
    const resultMsg = _('resultOverlay').querySelector('.result-msg');
    if (result.success) {
        resultMsg.classList.remove('error');
        resetForm(form);
        resultMsg.innerHTML = 'thank you, your message was sent successfully';
        return;
    }
    resultMsg.classList.add('error');
    resultMsg.innerHTML = result.error;
}
function resetForm(form) {
    form.reset();
    form.message.style.height = '';
    const inputs = main.querySelectorAll('.input-fields');
    inputs.forEach(input => { input.classList.remove('validate'); });
}
function onOverlayClick(e) {
    if (e.target.classList.contains('overlay')) {
        document.querySelector(":root").classList.remove('no-scroll');
        fadeOut(_('resultOverlay'));
    }
}

//HAMBURGER
function toggleHamb(e) {
    this.classList.toggle('change');
    if (nav.classList.contains('nav-transform')) { return nav.classList.remove('nav-transform'); }
    nav.classList.add('nav-transform');
}

//IMAGE OVERLAY
function onImgOverlay(e) {
    const newDim = getImgDimensions(this);
    const width = newDim.width;
    const height = newDim.height;
    const album = imgOverlay.querySelector('img');
    //reset image
    album.removeAttribute('src');
    document.querySelector(":root").style.setProperty("--imgWidth", 0 + "px");
    document.querySelector(":root").style.setProperty("--imgHeight", 0 + "px");
    //set image
    album.setAttribute('src', this.getAttribute('src'));
    album.setAttribute('alt', this.getAttribute('alt'));
    _('imgCaption').innerHTML = this.getAttribute('alt');
    document.querySelector(":root").style.setProperty("--imgWidth", width + "px");
    document.querySelector(":root").style.setProperty("--imgHeight", height + "px");
    showOverlay(imgOverlay, imgOverlay, 'image-overlay-change');
}
function getImgDimensions(img) {
    const bounds = img.getBoundingClientRect();
    const width = bounds.width;
    const height = bounds.height;
    var newWidth, newHeight;
    if (height > width) {
        const ratio = width / height;
        newHeight = Math.min(window.innerHeight - 150, height);
        newWidth = Math.min(window.innerWidth - 60, newHeight * ratio);
    }
    else { newWidth = Math.min(window.innerWidth - 60, window.innerHeight - 150); }
    return { width: newWidth, height: newHeight };
}

//VIDEO THUMBS
function onVidClick(e) {
    const src = this.getAttribute('data-src');
    if (src.length === 0) { return; }
    const thumb = this.querySelector('.thumb-wrapper');
    this.replaceChild(appendVideo(src), thumb);
}
function appendVideo(src) {
    const ifr = createEl('iframe', '');
    ifr.setAttribute('src', src);
    ifr.setAttribute('frameborder', '0');
    ifr.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
    ifr.setAttribute('allowfullscreen', '');
    return ifr;
}

//RESIZE
window.addEventListener('resize', onResize, false);
function onResize() {
    const vh = window.innerHeight / 100;
    document.querySelector(":root").style.setProperty("--vh", vh + "px");
}
onResize();